import React, { useEffect, useState } from 'react';
import './DownloadPage.scss';
import { gBaseUrl, URLConfig } from '../../utils/config';
import { useLocation } from 'react-router-dom';

const DownloadPage: React.FC<{}> = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pdf_token = queryParams.get('pdf_token');

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

  useEffect(() => {
    if (pdf_token) {
      const downloadUrl = `${gBaseUrl}/pdf/download/${pdf_token}`;
      setDownloadUrl(downloadUrl);
      window.location.href = downloadUrl;
    } else {
      console.log('PDF token not found in route parameters');
    }
  }, [pdf_token]);

  return (
    <div className='download_page_content_wrapper'>
      <div className='content'>
        <img
          className='logo'
          src={`${URLConfig.operatorWebUrl}/images/logo-transparent.png`}
          alt='Fully Booked Logo'
        />
        <h1 className='headline'>Welcome to Fully Booked</h1>
        <p className='description'>
          Your file will be downloaded automatically. If the download does not
          start, please click the button below.
        </p>
        <p className='download-message'>
          {downloadUrl ? (
            <p>
              Your file is ready to download.{' '}
              <a href={downloadUrl} download>
                Click here to download
              </a>
            </p>
          ) : (
            <p>Processing your request...</p>
          )}
        </p>
      </div>
      <img
        className='screenshot'
        src={`${URLConfig.operatorWebUrl}/images/app-ss.jpg`}
        alt='App Screenshot'
      />
    </div>
  );
};

export default DownloadPage;

import React, { Suspense } from 'react';
import Layout from './components/Layout/Layout';
import LoadingSpinner from './components/UI/LoadingSpinner';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import DownloadPage from './pages/download/DownloadPage';

const App = () => {
  return (
    <Layout>
      <Suspense
        fallback={
          <div className='centered'>
            <LoadingSpinner />
          </div>
        }>
        <Routes>
          <Route path='/'>
            <Route index={true} element={<HomePage />} />
          </Route>

          <Route path='download'>
            <Route index={true} element={<DownloadPage />} />
          </Route>

          <Route path='*' element={<HomePage />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;

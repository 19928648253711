import React, { useEffect } from 'react';
import './HomePage.scss';
import { URLConfig } from '../../utils/config';

const HomePage: React.FC<{}> = (props) => {
  useEffect(() => {
    const url = window.location.href;
    const applinkIndex = url.indexOf('/applink');

    if (applinkIndex !== -1) {
      const pathAfterApplink = url.substring(applinkIndex + 9);
      const schemeUrl = `fbookd://${
        pathAfterApplink ? pathAfterApplink : 'home'
      }`;
      window.location.href = schemeUrl;
    }
  }, []);

  return (
    <div className='home_page_content_wrapper'>
      <div className='content'>
        <img
          className='logo'
          src={`${URLConfig.operatorWebUrl}/images/logo-transparent.png`}
          alt='Fully Booked Logo'
        />
        <div className='headline'>Fully Booked</div>
        <div className='description'>
          Download our app from the App Store or Play Store and manage your
          bookings effortlessly!
        </div>
        <div className='buttons'>
          <a
            href='https://play.google.com/store/apps/details?id=uk.fullydashbooked.mobileapp'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src={`${URLConfig.operatorWebUrl}/images/play-store-download.png`}
              alt='Download from Play Store'
            />
          </a>
          <a
            href='https://apps.apple.com/us/app/fully-booked/id6468971635'
            target='_blank'
            rel='noopener noreferrer'>
            <img
              src={`${URLConfig.operatorWebUrl}/images/app-store-download.png`}
              alt='Download from App Store'
            />
          </a>
        </div>
      </div>
      <img
        className='screenshot'
        src={`${URLConfig.operatorWebUrl}/images/app-ss.jpg`}
        alt='App Screenshot'
      />
    </div>
  );
};

export default HomePage;

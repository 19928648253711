import { useLocation } from 'react-router-dom';
import './Layout.scss';

const Layout: React.FC<{ children: React.ReactNode }> = (props) => {
  const { pathname } = useLocation();

  return (
    <div className='wrapper'>
      <main className='main'>
        <div className='main_content'>{props.children}</div>
      </main>
    </div>
  );
};
export default Layout;
